import React, { useState, useEffect } from 'react'
import { getData, sendData } from "../../services/HttpClient"
import { ProntoContainer } from '../layout/Containers'
import LoadingContainer from '../layout/LoadingContainer'
import Placeholder from '../layout/Placeholder'
import Checkbox from '../Form/Checkbox'
import BackButton from '../backbutton/BackButton'
import Button from '../UI/Button'
import { Link } from "react-router-dom"
import css from "./Gates.module.css"
import { defaultNotifications } from "../../utilis/Notifications"

function Gates({ history, ...props }) {
  const storeId = props.match.params.storeid
  const [fetching, setFetching] = React.useState(true)
  const [store, setStore] = React.useState({})
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (storeId) {
        const result = await getData(
          '/api/stores/' + storeId
        )
        setStore(result.data)
        setFetching(false)
      }
    }

    fetchData()
  }, [storeId])

  function setStoreValue(event) {
    const newStore = { ...store }
    newStore[event.target.name] = event.target.checked
    setStore(newStore)
  }

  async function save() {
    setSaving(true)
    const result = await sendData('/api/stores/edit', store, 'POST')
    setSaving(false)
    if (result.ok) {
      history.push('../unmanned')
    }
    else {
      defaultNotifications.error(Placeholder.Gates)
    }
  }

  return (
    <div>
      <BackButton
        backLabel={Placeholder.Unmanned}
        editPage={true}
        to="../unmanned">
        {Placeholder.Gates}
      </BackButton>
      <ProntoContainer>
        <LoadingContainer loading={fetching}>
          <Checkbox checked={store.hasEntranceGate} name="hasEntranceGate" label={Placeholder.HasEntranceGate} onChange={(e) => setStoreValue(e)} />
          <Checkbox checked={store.hasExitGate} name="hasExitGate" label={Placeholder.HasExitGate} onChange={(e) => setStoreValue(e)} />
          <div className="flex">
            <Button type="save" loading={saving} onClick={save}>{Placeholder.Save}</Button>
            <Link to={"../unmanned"}>
              <Button type="cancel" className={css.ml}>
                {Placeholder.Cancel}
              </Button>
            </Link>
          </div>
        </LoadingContainer>
      </ProntoContainer>
    </div>
  )
}

export default Gates